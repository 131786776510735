<template>
  <div class="grid grid-cols-2 gap-2">
    <div>
      <label>
        Form
        <select name="form" id="form" v-model="kalkulation.form">
          <option value="1A1">1A1</option>
          <option value="4A2">4A2</option>
        </select>
      </label>

      <label>
        Durchmesser
        <select
          name="durchmesser"
          id="durchmesser"
          v-model="kalkulation.durchmesser"
        >
          <option value="100">100</option>
          <option value="200">200</option>
        </select>
      </label>

      <label>
        Belagbreite
        <input
          name="belagbreite"
          id="belagbreite"
          type="text"
          v-model="kalkulation.belagbreite"
        />
      </label>

      <label>
        Belaghöhe
        <input
          name="belaghoehe"
          id="belaghoehe"
          type="text"
          v-model="kalkulation.belaghoehe"
        />
      </label>

      <label>
        Belaghöhe
        <input
          name="belaghoehe"
          id="belaghoehe"
          type="text"
          v-model="kalkulation.belaghoehe"
        />
      </label>

      <label>
        Gesamthöhe
        <input
          name="gesamthoehe"
          id="gesamthoehe"
          type="text"
          v-model="kalkulation.gesamthoehe"
        />
      </label>

      <label>
        Bohrung
        <input
          name="bohrung"
          id="bohrung"
          type="text"
          v-model="kalkulation.bohrung"
        />
      </label>

      <label>
        Körnung
        <select
          name="koernung"
          id="koernung"
          v-model="kalkulation.qualitaet.koernung"
        >
          <option value="100">100</option>
          <option value="200">200</option>
        </select>
      </label>

      <label>
        Konzentration
        <select
          name="konzentration"
          id="konzentration"
          v-model="kalkulation.qualitaet.konzentration"
        >
          <option value="100">100</option>
          <option value="200">200</option>
        </select>
      </label>

      <label>
        Bindung
        <select
          name="bindung"
          id="bindung"
          v-model="kalkulation.qualitaet.bindung"
        >
          <option value="100">100</option>
          <option value="200">200</option>
        </select>
      </label>

      <label>
        Pressvolumen
        <input
          name="pressvolumen"
          id="pressvolumen"
          type="text"
          readonly
          v-model="kalkulation.berechnet.pressvolumen"
        />
        cm³/Stück
      </label>

      <label>
        Korngewicht
        <input
          name="korngewicht"
          id="korngewicht"
          type="text"
          readonly
          v-model="kalkulation.berechnet.korngewicht"
        />
        g/Stück
      </label>

      <label>
        Bindungsgewicht
        <input
          name="bindungsgewicht"
          id="bindungsgewicht"
          type="text"
          readonly
          v-model="kalkulation.berechnet.bindungsgewicht"
        />
        g/Stück
      </label>

      <label>
        Karat
        <input
          name="karat"
          id="karat"
          type="text"
          readonly
          v-model="kalkulation.berechnet.karat"
        />
        g/Stück
      </label>
    </div>
    <div>
      <label>
        Körperpreis
        <input
          name="koerperpreis"
          id="koerperpreis"
          type="text"
          readonly
          v-model="kalkulation.berechnet.koerperpreis"
        />
      </label>

      <label>
        Kornpreis
        <input
          name="kornpreis"
          id="kornpreis"
          type="text"
          readonly
          v-model="kalkulation.berechnet.kornpreis"
        />
      </label>

      <label>
        Preis
        <input
          name="preis"
          id="preis"
          type="text"
          readonly
          v-model="kalkulation.berechnet.preis"
        />
      </label>

      <label>
        Ist Händler
        <input
          type="checkbox"
          name="haendlerrabatt"
          id="haendlerrabatt"
          v-model="kalkulation.haendlerrabattActive"
        />
      </label>

      <label>
        Händlerrabatt
        <input
          name="haendlerrabatt"
          id="haendlerrabatt"
          type="text"
          readonly
          v-model="kalkulation.berechnet.haendlerrabatt"
        />
      </label>

      <label>
        Verkaufspreis
        <input
          name="verkaufspreis"
          id="verkaufspreis"
          type="text"
          readonly
          v-model="kalkulation.berechnet.verkaufspreis"
        />
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "Calculator",
  data() {
    return {
      kalkulation: {
        form: "",
        winkel: "",
        durchmesser: "",
        belagbreite: "",
        belaghoehe: "",
        gesamthoehe: "",
        bohrung: "",
        qualitaet: {
          koernung: "",
          konzentration: "",
          bindung: ""
        },
        berechnet: {
          pressvolumen: "",
          korngewicht: "",
          bindungsgewicht: "",
          karat: "",
          koerperpreis: "",
          kornpreis: "",
          preis: "",
          haendlerrabatt: "",
          verkaufspreis: ""
        },
        haendlerrabattActive: true
      },
      rohmaterial: {
        aluminium: 5.55,
        durchmesser: 210,
        spezifischesGewicht: 2.85,
        gesamthoehe: 30
      },
      kosten: {
        allgemein: 31.87,
        personal: 14.36
      }
    };
  },
  // TODO: Kornpreis abrufen
  // TODO: Bindungspreis berechnen
  // TODO: Alupreis abrufen

  // TODO: Korngewicht berechnen
  // [] Coatingzahl
  // [] Karatanteil
  //    [] Konzentrationswert
  //    [] Durchmesser
  //    [] Belagbreite
  //    [] Belaghöhe

  // TODO: Aluminium Berechnung
  // [x] Radius in Metern (Alu-Rohmaterial Durchmesser / 2 / 100)
  // [x] Radius mal Radius (Radius in Metern * Radius in Metern)
  // [x] Höhe in Metern (Gesamthöhe / 100)
  // [x] Alugewicht (Pi * Radius mal Radius * Höhe in Metern * 2,85)

  // TODO: Bearbeitungszeiten abrufen
  methods: {
    calculateAluBody() {
      const radiusInMetern = this.rohmaterial.durchmesser / 2 / 100;
      const radiusMalRadius = radiusInMetern * radiusInMetern;
      const hoeheInMetern = this.gesamthoehe / 100;
      const aluminiumGewicht =
        Math.PI * radiusMalRadius * hoeheInMetern * this.spezifischesGewicht;

      return aluminiumGewicht * this.aluminiumPrice;
    }
  }
};
</script>

<style scoped>
label {
  @apply flex;
  @apply flex-row;
  @apply items-center;
  @apply gap-4;
}
</style>
