<template>
  <div>
    <h2>Kalkulation</h2>

    <calculator />
  </div>
</template>

<script>
import Calculator from "@/components/Calculator.vue";

export default {
  components: { Calculator }
};
</script>

<style></style>
